@import url('https://fonts.googleapis.com/css2?family=Jua&family=Tilt+Warp&family=Varela+Round&display=swap');
html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Varela Round', sans-serif;
}

a {
  color: #7dd3fc;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  font-family: 'Varela Round', sans-serif;
}

html {
  color-scheme: dark;
}
body {
  color: #006F56;
  background: #FCF9EE !important;
}

.container-fluid {
  padding-left: 150px !important;
  padding-right: 150px !important;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}


/* .main {
  display: flex;
  flex-direction: column;
  height: 100vh;
} */

.connect-button {
  background-color: #006F56 !important;
  color: #fff !important;
  height: 60px !important;
  margin-top: 1px !important;
  border-radius: 10px !important;
  /* border-radius: 0 !important;
   */

}

.btn-primary {
  background-color: #E68C3A !important;
  color: #ffffff !important;
  border-radius: 10px !important;
  padding: 15px 45px !important;
  border: 1px solid #E68C3A !important;
}

.btn-primary:hover {
  background-color: #EDB17B !important;
  color: #ffffff !important;
  border: 1px solid #EDB17B !important;
}

.btn-light {
  background-color: #ffffff !important;
  color: #E68C3A !important;
  border-radius: 10px !important;
  padding: 15px 45px !important;
  border: 1px solid #ffffff !important;
}

.btn-light:hover {
  background-color: #E68C3A !important;
  color: #ffffff !important;
  border: 1px solid #E68C3A !important;
}

.page-title {
  border-bottom: 1px solid #006F56;
}

.page-title h2 {
  font-family: 'Tilt Warp', cursive !important;
  font-size: 45px;
  color: #006F56;
}
.active>.page-link, .page-link.active {
  background-color: #E68C3A !important;
  border: 1px solid #E68C3A !important;
  color: #ffffff !important;
}

.page-link {
  color: #E68C3A !important;
}


.type-button {
  height: 100%;
  padding: 5px 30px !important;
}

.type-button.active {
  background-color: #ffffff !important;
  border: 1px solid #E68C3A !important;
  color: #E68C3A !important;
}

/* Change the color of the scrollbar track (background) */
.cards-row::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
  height: 10px;
}

.cards-row::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Set the background color */
}

/* Change the color of the scrollbar handle (thumb) */
.cards-row::-webkit-scrollbar-thumb {
  background-color: #888; /* Set the color of the handle */
  border-radius: 6px; /* Rounded corners for the handle */
}

/* Optionally, you can also style the scrollbar corners and grip */
.cards-row::-webkit-scrollbar-corner {
  background-color: #f1f1f1; /* Set the color of the corners */
}




@media (max-width: 768px) {
  .container-fluid {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .btn-primary {
    width: 100%;
  }
  
  
}

@media (max-width: 480px) {
  .container-fluid {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
}