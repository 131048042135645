.footer-wrap {
    background-color: #7C5D45 !important;
    color: #FFFFFF;
   
}
.footer-wrap .container-fluid {
    padding-top: 70px;
    padding-bottom: 20px;
}

.about-txt {
    font-size: 12px !important;
}

.footer-img {
    width: 30%;
    padding-bottom: 50px;
}

.footer-wrap ul {
    list-style: none;
    padding-left: 0;
    /* Remove the default bullet points */
}

.footer-wrap ul li{
    padding-bottom: 20px;
    /* Remove the default bullet points */
}

.copyright-txt {
    text-align: center;
    font-size: 12px;
    border-top: 1px solid #FFFFFF;
    padding: 20px 0; 
}

.footer-link {
    color: #FFFFFF;
    text-decoration: none;
}