
.main-image {
    max-width: 100%; 
    max-height: 100vh; 
    margin: auto;
}

.title {
    font-size: 35px;
    text-align: left;
    color: #006F56 !important;
}

.thumbs-wrapper {
    text-align: center;
    overflow-y: auto;
}
.carousel .thumb {
    border: 2px solid #D3D3D3 !important;
}
.carousel .thumb.selected, .carousel .thumb:hover {
    border: 2px solid #999999 !important;
    /* border */
}

.product-wrap {
    background-color: #FCF9EE !important;
  font-family: 'Varela Round', sans-serif;
  color: #006F56 !important;
}

.form-control{
    background-color: #FCF9EE !important;
    color: #006F56 !important;
    border: 1px solid #006F56 !important;
    cursor: default;
}
#button-addon1, #button-addon2{
    background-color: #FCF9EE !important;
    color: #006F56 !important;
    border: 1px solid #006F56 !important;
    /* padding: 10px 30px !important; */

}

#button-addon1 {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

#button-addon2 {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

input:focus, input.form-control:focus {

    outline:none !important;
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}

.btn-cart {
    /* padding: 10px 30px !important; */
}

span.h5 {
    font-size: 24px !important;
}

.divider {
    color: #006F56;
    height: 2px;
    opacity: 1;
    
}

.quantity-input {
    width: 170px;
}

.carousel .control-arrow {
    margin: auto -15px;
    /* background-color: #239f96 !important; */
    opacity: 1 !important;
}


.carousel .control-next.control-arrow:before {
    border-left: 8px solid #239f96 !important;
}

.carousel .control-prev.control-arrow:before {
    border-right: 8px solid #239f96 !important;
}

.form-select {
    background-color: #FCF9EE;
    color: #006F56;
}




@media (max-width: 768px) {
    .quantity-input {
        width: 350px;
    }
}