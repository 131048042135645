.modal-80w {
    max-width: none!important;
    width: 80% !important;
}

.sub-title {
    font-size: 18px;
}


.flex-table {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .flex-table .table-row {
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid #ccc;
    display: flex;
    flex-wrap: wrap;
  }
  
  .flex-table .table-row .table-header {
    font-weight: bold;
    flex-basis: 100%;
  }
  
  .flex-table .table-row .table-cell {
    flex-basis: 50%;
  }
  @media (max-width: 768px) {
    .modal-80w.modal-dialog{
      width: 100vw !important;
      max-width: none !important;
      height: 100% !important;
      margin: 0 !important;
      padding: 0 !important;
      border-radius: 0 !important;
  }
  .modal-content {
    border-radius: 0 !important;
  }

  .modal {
    margin: 0 !important;
      padding-left: 0px !important;
  }
  
  }
  
  @media (min-width: 576px) {
    .flex-table .table-row .table-cell {
      flex-basis: 25%;
    }
  }