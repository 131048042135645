.navbar {
  background-color: #FCF9EE !important;
  border-bottom: 1px solid #006F56;
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: 'Varela Round', sans-serif;

  /* position: absolute; */
  /* width: 100%; */
}

.navbar-brand {
  font-weight: 300;
  margin-right: 30px;
}

.navbar-img {
  width: 60px;
  
}

.navbar-dark .navbar-nav .nav-link {
  padding-bottom: 0px;
  padding-right: 0;
  padding-left: 0;
  color: #2F6D57;
}





.nav-item {
  font-size: 16px !important;
  /* border-bottom: 1px solid red; */
  padding-right: 15px;
  padding-left: 15px;
}

.nav-link:hover,
.nav-link.active {
  border-bottom: 1px solid #006F56;
  color: #2F6D57
}

.nav-link.dropdown-toggl {
  padding-top: 16px;
}

.nav-link.dropdown-toggle:hover,
.nav-link.dropdown-toggle.active {
  border-bottom: none;
}

.nav-link.cart-button:hover,
.nav-link.cart-button.active {
  border-bottom: none;
}

.profile-wrap .rounded-circle {
  margin-right: 10px;
}

.nav-item.dropdown {
  border-left: 1px solid #239f96;
  border-right: 1px solid #239f96;
}


.tw-connected-wallet {
  /* background-color: #fff !important; */
  background: #FCF9EE !important;
  border-color: #006F56 !important;

}

.css-9y9lc8 {
  color: #006F56 !important;
}

.account-list {
  list-style-type: none;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-menu {
  width: 250px;
  /* or any other value you want */
}

.profile-wrap {
  padding: 10px;
  font-weight: 600;
}

a.dropdown-item.active, a.dropdown-item:hover {
  border-left: 3px solid #006F56;
  background-color: #FCF9EE !important;
  color: #006F56 !important;
  /* border-bottom: 1px solid red */
}

.dropdown-item {
  border-left: 3px solid #FCF9EE;
  padding-top: 15px;
  padding-bottom: 15px;
}

.dropdown-divider {
  margin: 0;
  border-top: 1px solid #2E4F4F;
}

a.dropdown-item:hover,
a.dropdown-item:focus,
a.dropdown-item:active {
  background-color: #CBE4DE;
  color: #212529;
}

.bi-cart,
.bi-person {
  font-size: 20px;
}

.badge {
  font-size: 10px;
  font-weight: 400;
  background-color: #D9904B !important;
}

.border-right {
  border-right: 1px solid #006F56;
  margin-right: 30px;
  padding-right: 30px;

}

.nav-item.icon-nav {
  padding-right: 20px !important;
  padding-left: 0 !important;
}

.nav-item.icon-nav .icon-button {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  /* padding-right: 10px !important; */
  /* padding-left: 0 !important; */
}


Button.icon-button {
  color: #006F56 !important;
  border: none !important;
  background: transparent !important;
  padding-right: 0px !important;
  padding-left: 0px !important;

}

Button.icon-button:hover {
  color: #006F56 !important;
  border: none !important;
  background: transparent !important;
}


@media (max-width: 991.98px) {
  .navbar-nav.right-menu {
    flex-direction: row !important;
  }

  .navbar-nav.right-menu .nav-item {
    /* margin: 0 auto; */
    margin: 0 !important;
    flex-grow: 1;

  }

  .currency-text {
    text-align: left !important;
    margin: 5px auto !important;
  }

}


.modal-right {
  color: #006F56;
  background-color: red !important;
  width: 25%;
  float: right;
  /* margin-left: auto;
  margin-right: 0; */
}

.modal-wrap {
  color: red;
  font-family: 'Varela Round', sans-serif;


}


.modal-body,
.modal-footer,
.modal-header {
  background-color: #FCF9EE;
  color: #006F56 !important;
}

.modal-header,
.modal-title {
  border: none;
  color: #006F56;
  padding-top: 20px;
  font-size: 24px;
}

.modal-footer {
  border: none;
}

.modal-body {
  padding: 0 30px;
  /* border-top: 1px solid red */
}

.modal-divider {
  color: #006F56;
  height: 2px;
  opacity: 1;
  width: 100%;
}

.modal-top {
  height: 50%;
}

.search-input .form-control {
  color: #006F56;
  outline: none;
  border: none !important;
  border-radius: 0;
  border-bottom: 1px solid #006F56 !important;
  font-size: 24px;
}

.search-icon {
  background-color: transparent;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #006F56 !important;
}

.search-input .form-control::placeholder {
  color: #006F56;
}

.search-form {
  width: 50%
}
.css-13cymwt-control, .css-t3ipsp-control, .css-t3ipsp-control:hover{
  outline: none !important;
  border: 1px solid #006F56 !important;
  background-color: transparent !important;
  box-shadow: none !important;
  
}


.css-1nmdiq5-menu{
  background-color: #FCF9EE !important;
}


.css-13cymwt-control:focus .css-t3ipsp-control:focus{
  outline:none !important;
  outline-width: 0 !important;
  box-shadow: none !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

@media (max-width: 768px) {
  .modal-right {
    width: 100%
  }

  .modal-top {
    height: 100%;
  }

  .navbar {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .nav-item.icon-nav i {
    /* padding-right: 20px !important; */
    font-size: 18px;
  }

  .search-form {
    width: 100%
  }

}
@media (max-width: 991px) {
  .modal-right {
    width: 100%
  }
}

@media (min-width: 1024px) {
  .modal-right {
    width: 40% !important;

  }
  /* CSS styles specific to tablets */
}

@media (min-width: 1200px) {
  /* CSS styles specific to desktop/laptop devices */
  .modal-right {
    width: 35% !important;

  }
}

