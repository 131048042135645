.card,
.card:hover {
  background-color: transparent;
  border: NONE;
  color: #006F56 !important;
}

.card-footer {
  border: none;
  background: transparent;
}


.product-card {
  padding: 0;
  border-radius: 0;
  color: #006F56 !important;
  /* border: 1px solid 161618 !important; */
  /* box-shadow: 0 6px 10px rgba(0, 0, 0, .08), 0 0 6px rgba(0, 0, 0, .05); */
  /* transition: .9s transform cubic-bezier(.155, 1.105, .295, 1.12), .9s box-shadow, .9s -webkit-transform cubic-bezier(.155, 1.105, .295, 1.12); */
  /* padding: 14px 80px 18px 36px; */
  cursor: pointer;
}
.card-text, .price-text {
  color: #006F56 !important;
  text-decoration: none !important;
  font-size: 16px;
}




.store-banner {
  padding: 20px;
  background-color: #006F56;
  color: #FCF9EE;


}

.store-banner h3 {
  font-size: 60px;
  font-family: 'Tilt Warp', cursive !important;
}


.shop-banner {
  background-color: #FCF9EE !important;
  color: #006F56 !important;
  padding: 60px;
  background-image: url(/public/images/store-banner-left.png), url(/public/images/store-banner-right.png);
  background-position: top left, bottom right;
  background-repeat: no-repeat;
  background-size: contain;


}

.shop-banner h3 {
  font-size: 45px;
  font-family: 'Tilt Warp', cursive !important;
}

.store-wrap {
  background-color: #FCF9EE !important;
  font-family: 'Varela Round', sans-serif;
}

.green-svg {
  color: #006F56 !important;
}

.shop-img {
  height: 43px !important;
  width: 160px !important;
}

.filter-banner {
  color: #006F56 !important;
  border-top: 1px solid #006F56;
  border-bottom: 1px solid #006F56;
  padding: 20px;
}

.filter-select {
  color: #006F56 !important;
  background-color: transparent !important;
  
}


@media (max-width: 768px) {
  .shop-banner{
    background-size: 150%;
  }
  
}
