.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .container {
    padding: 0 40px 0 !important;
    margin: 0;
  }
  .nft-desc {
    font-size: 18px !important;
    font-weight: 200;
    margin-top: 10px;
  }
  
  .nft-card {
    padding: 0;
    border-radius: 0 !important;
    cursor: default;
    /* border: 1px solid 161618 !important; */
    box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
      transition: .9s transform cubic-bezier(.155,1.105,.295,1.12),.9s box-shadow,.9s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
  /* padding: 14px 80px 18px 36px; */
  /* cursor: pointer; */
  }
  
  .card-img {
    height: 448px !important;
    object-fit: contain;
    border-bottom: 1px solid #eaeaea; 
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    background-color: #ffffff;
  }
  
  .nft-price {
    color: #239f96;
    font-size: 18px !important;
  }
  
  
  
  .nft-card:hover{
     transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
  }
  
  
  .modal-right {
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: auto;
  }

 .filter-select {
  width: 200px
 }

 
 .nokko-svg {
  /* width: 230px; */
 }
 



@media (max-width: 768px) {
  .nft-column {
    flex-direction: column!important;
  }

  .filter-select {
    width: 150px
   }
}
 