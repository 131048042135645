.container {
    margin: auto 0;
    min-width: 100%;
    padding: 0 50px;
}

.card {
    max-width: 100% !important;
}

  
 