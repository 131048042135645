section {
  background: #FCF9EE !important;
}

.ship-card, .ship-card:hover {
    border: 1px solid #eaeaea;
    border-radius: 0;
}

.ship-card.selected {
    border: 2px solid rgba(35, 159, 150, 0.3);
    border-radius: 0;
}

.bi-check-circle-fill {
    color: #239f96;
}


.paypal-button {
    display: inline-block;
    /* background-color: red; */
    color: #ffffff;
    padding: 20px 50px !important;
    border-radius: 10px !important;
    text-decoration: none;
    font-family: Arial, sans-serif;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

 
  
  .paypal-button:hover {
    /* background-color: red; */
    color: #ffffff;
  }
  
  .paypal-button:focus {
    outline: none;
  }
  
  /* Optional Button Size Styles */
  .paypal-button.small {
    padding: 8px 16px;
    font-size: 14px;
  }
  
  .paypal-button.large {
    padding: 12px 24px;
    font-size: 18px;
  }

  .region-dropdown{
    background-color: transparent;
    color: #ffffff;
    border-color: #eaeaea;
  }
  
  .coupon-link {
    color: #E68C3A;
  }

  .payment-buttons .btn-primary {
    margin-right: 10px;
  }

  .cards-container {
    overflow-x: auto;
    
  }
  
  .cards-row {
    flex-wrap: nowrap;
    overflow-x: scroll;
    padding-bottom: 15px; /* Adjust this value as needed */
    
  }

  .ship-card {
    flex: 0 0 auto; /* Ensures the cards don't expand and maintain their original width */
    width: 370px; /* Adjust this value to set the width of each card */
    margin-right: 15px; /* Adjust this value to set the gap between cards */
  }

  


  @media (max-width: 768px) {
    .paypal-button-wrap{
      padding-top: 10px;
    }
    .checkout-wrap button, .payment-buttons, .paypal-button-wrap {
      width: 100%;
    }
  }