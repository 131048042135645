.container-wrap {
  font-family: 'Varela Round', sans-serif;
}


.main-banner {
  color: #006F56;
  padding-top: 130px;
  padding-bottom: 120px;
  /* flex: 1 1 auto; */
  background: #FCF9EE;
  background-image: url(/public/images/home-banner-left.png), url(/public/images/home-banner-right.png);
  background-position: left top, bottom right;
  background-repeat: no-repeat, no-repeat;
  background-size: contain;
  /* padding-top: 108px !important; */
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Center vertically */
  justify-content: center;
}

.main-header h1 {
  
  font-size: 60px;
  font-family: 'Jua', sans-serif !important;
}


.nft-collection-wrap {
  padding-top: 100px;
  padding-bottom: 100px;
  color: #006F56;
  background-color: #FCF9EE;
  
 
}

.nft-collection-wrap .main-header {
  padding-bottom: 50px;
}

.nft-collection-wrap .main-header p {
  /* display: inline-block; */
  display: inline-block;
    height: auto;
  margin: 0;
  font-size: 45px;
  font-family: 'Tilt Warp', cursive !important;
}

.nft-collection-wrap h3 {
  font-size: 24px;
}


.explore-korea-wrap {
  padding-top: 90px;
  padding-bottom: 90px;
  color: #FCF9EE;
  background-color: #005340;
  background-image: url(/public/images/explore-korea.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.explore-korea-wrap .main-header p {
  margin: 0;
  font-size: 45px;
  font-family: 'Tilt Warp', cursive !important;
}

.explore-korea-img {
  width: 500px;
}

.shop-wrap {
  padding-top: 90px;
  padding-bottom: 90px;
  color: #006F56;
  background-color: #FCF9EE;
  background-image: url(/public/images/shop-banner-left.png), url(/public/images/shop-banner-right.png);
  background-position: top left, bottom right;
  background-repeat: no-repeat;
  background-size: cover;
}

.shop-merchandise-img{
  width: 100%;
}

.shop-wrap .main-header p {
  margin: 0;
  font-size: 45px;
  font-family: 'Tilt Warp', cursive !important;
}

.connect-btn, .view-btn {
  padding: 15px 45px !important;
  height: 56px !important;
 }

 .slick-initialized .slick-slide{
  margin-right: 50px !important;
 }

@media (max-width: 768px) {
  .main-banner {
    padding-top: 80px;
    padding-bottom: 80px;
    background-image: none;
  }
  .home-img {
    padding-bottom: 30px !important;
  }
  .nft-collection-wrap {
    padding-top: 30px;
  }

  .slider-wrap{
    padding-top: 30px
  }
  .explore-korea-wrap .main-header {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .shop-wrap {
    background-image: none;
    padding-top: 50px;
  }
  .shop-merchandise-img{
    padding-top: 30px;
  }

  .title-col {
    /* position: absolute;  */
    top: 0; 
    left: 0;
  }
  
  .title-col-bottom {
    position: absolute; 
    top: 50px; 
    left: 0;
  }
  
  .collection-content-wrap {
    margin-top: 50px
  }

  .content-wrap {
    margin-top: 90px;

  }

  .explore-korea-wrap .main-header {
    margin-top: 30px;
  }

  .explore-korea-wrap .title-col {
    position: absolute;
  }

  .explore-korea-wrap .main-header p, 
  .shop-wrap .main-header p,
  .nft-collection-wrap .main-header p  {
    font-size: 40px;
  }

  .connect-btn, .view-btn {
    width: 100% !important;
  }
 
  .text-mobile{
    width: 100% !important;
    padding: 0 !important;
  }

  .slick-initialized .slick-slide{
    margin-right: 0 !important;
   }

}

@media (max-width: 1349.99px) and (min-width: 1450px) {
  .slick-initialized .slick-slide{
    margin-right: 0 !important;
   }
}

@media (min-width: 1350px) {
  .slick-initialized .slick-slide{
    margin-right: 80px !important;
   }
}

@media (min-width: 1600px) {
  .slick-initialized .slick-slide{
    margin-right: 0px !important;
   }
}

@media (min-width: 990px) and (max-width: 1349.99px)  {
  .slick-initialized .slick-slide{
    margin-right: 120px !important;
   }
}

@media (min-width: 768px) {
  .shop-merchandise-img{
    padding-top: 30px;
  }
  /* .slick-initialized .slick-slide{
    margin-right: 0 !important;
   } */
}